<template>
  <div class="tw-flex tw-w-full tw-min-h-screen tw-flex-col">
  <div class="tw-flex tw-w-full  tw-flex-col lg:tw-px-80 tw-px-4">
  <booking-steps />
  <div class="back tw-mb-8" @click="$router.push({name:'RoundTripSelectSeats'})"><v-icon>mdi-chevron-left</v-icon>Back</div>
  <div class="tw-flex tw-w-full tw-flex-col tw-py-8">
    <p class="section-header">Complete Travel Details</p>
    <div class="tw-flex tw-w-full tw-flex-col" v-for="(seat,index) in seats" :key="index">
      <div class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row tw-justify-between tw-items-start lg:tw-items-center tw-py-5">
        <p class="passenger">Passenger {{index+1}} Details</p>
        <p class="use-code">Use Traveller’s Code <span v-if="index > 0" class="or"> OR </span>
          <span v-if="index > 0" class="use-code">Use Primary Details</span></p>
      </div>
      <customer-details/>
      <v-divider/>
    </div>

  </div>
</div>
    <trip-details/>
  </div>
</template>

<script>
import BookingSteps from "@/components/reuseables/bookingAndHiring/BookingSteps";
import {mapGetters} from "vuex";
import CustomerDetails from "@/views/bookings/CustomerDetails";
import TripDetails from "@/components/reuseables/bookingAndHiring/TripDetails";
export default {
  name: "ReturnTripTravellerDetails",
  components: {TripDetails, CustomerDetails, BookingSteps},
  data(){
    return{

    }
  },
  computed:{
    ...mapGetters("returnTripDepartureSeatsSelection",["seats"])
  }
}
</script>

<style scoped lang="scss">
.section-header{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  color: #4F4F4F;
}

.passenger{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #004AAD;
}

.use-code{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #004AAD;

}
.or{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #263238;
}

.back{
  display: flex;
  align-items: center;
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #4F4F4F;
  width: max-content;
  text-transform: none;
  cursor: pointer;
}
</style>